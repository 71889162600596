.Customer .topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: var(--border-default);
}

.Customer .table {
  font-family: var(--font-family);
  border: none;
}
.Customer .table .MuiDataGrid-row {
  min-height: 40px !important;
  max-height: 40px !important;
}
.Customer .table .MuiDataGrid-cell {
  outline: none !important;
  border-right: var(--border-default);
  min-height: 40px !important;
  max-height: 40px !important;
}
.Customer .table .MuiDataGrid-cell:first-child {
  border-right: none;
}
.Customer .table .MuiDataGrid-cell:first-child,
.Customer .table .MuiDataGrid-cell:nth-child(7) {
  border-right: none;
}
.Customer .table .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  color: var(--color-default-border);
}
.Customer .table .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
  height: calc(-268px + 100vh) !important;
}
.Customer .table .active {
  border: var(--border-default);
  color: var(--color-default);
  padding: 2px 5px;
  border-radius: var(--br-default);
}

.Customer .ctn-profile {
  visibility: hidden;
}
.Customer .ctn-profile.active {
  visibility: visible;
}
.Customer .ctn-profile .bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0);
  transition: all 0.5s;
  z-index: 100;
}
.Customer .ctn-profile.active .bg {
  background: rgba(0, 0, 0, 0.25);
}
.Customer .ctn-profile .profile {
  position: fixed;
  overflow-y: auto;
  top: 30px;
  bottom: 30px;
  right: 30px;
  width: 660px;
  transform: translateX(700px);
  background: rgb(255, 255, 255);
  border-radius: var(--br-default);
  box-shadow: var(--box-shadow);
  transition: all 0.5s;
  z-index: 100;
}
.Customer .ctn-profile.active .profile {
  transform: translateX(0);
}
.Customer .ctn-profile .profile .padding {
  border-bottom: var(--border-big);
}
.Customer .ctn-profile .profile .padding:last-child {
  border-bottom: 0;
}
.Customer .ctn-profile .profile .tab {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Customer .ctn-profile .profile .tab div {
  display: flex;
  align-items: center;
}
.Customer .ctn-profile .profile .tab .icon {
  cursor: pointer;
  font-size: 36px;
  margin-left: 10px;
  color: var(--color-gray);
}
.Customer .ctn-profile .profile .tab .icon:hover {
  color: var(--color-gray-hover);
}
.Customer .ctn-profile .profile .ctn-title {
  display: flex;
  align-items: center;
}
.Customer .ctn-profile .profile .ctn-title .Pallet {
  margin-left: 10px;
}
.Customer .ctn-profile .profile .ctn-infos,
.Customer .ctn-profile .profile .ctn-infos .ctn-business {
  display: flex;
  align-items: center;
}
.Customer .ctn-profile .profile .ctn-infos img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.Customer .ctn-profile .profile .logo-entreprise {
  width: 15px !important;
  height: 15px !important;
  border-radius: 2px !important;
  margin-right: 5px !important;
}
.Customer .ctn-profile .profile .ctn-mid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: var(--border-default);
  border-radius: var(--br-default);
  padding: 10px 20px;
  margin: 30px 0;
}
.Customer .ctn-profile .profile .ctn-mid div {
  width: 50%;
  border-left: var(--border-default);
  padding-left: 20px;
}
.Customer .ctn-profile .profile .ctn-mid div:first-child {
  border-left: none;
  padding-left: 0;
}
.Customer .ctn-profile .profile .ctn-mid .text-sm {
  text-transform: uppercase;
}
.Customer .ctn-profile .profile .title-sm {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--color-gray-hover);
}
.Customer .ctn-profile .profile .ctn-details {
  margin-bottom: 20px;
}
.Customer .ctn-profile .profile .ctn-details .flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}
.Customer .ctn-profile .profile .ctn-details .flex .inner {
  width: 50%;
}
.Customer .ctn-profile .profile .ctn-details .flex .inner .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: var(--border-default);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.Customer
  .ctn-profile
  .profile
  .ctn-details
  .flex
  .inner
  .container:last-child {
  border-bottom: 0;
}
.Customer .ctn-profile .profile .ctn-details .flex .active {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 3px;
  border: var(--border-default);
  border-radius: var(--br-default);
  color: var(--color-default);
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
  transition: var(--transition-default);
}
.Customer .ctn-profile .profile .ctn-details .flex .active:hover {
  background-color: var(--color-gray-bg);
}
.Customer .ctn-profile .profile .ctn-details .flex .active:first-child {
  margin-top: 0px;
}
.Customer .ctn-profile .profile .ctn-details .flex .inner .container div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Customer .ctn-profile .profile .ctn-details .flex .inner .container a {
  text-decoration: none;
}
.Customer
  .ctn-profile
  .profile
  .ctn-details
  .flex
  .inner
  .container
  .ctn-entreprise {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Customer .ctn-profile .profile .ctn-details .list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--br-default);
  border: var(--border-default);
  padding: 8px 15px;
}
