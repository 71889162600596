.InputComponents .text-sm {
  font-weight: 500;
  color: #45515b;
}
.InputComponents .container {
  cursor: text;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: var(--border-radius-little);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 5px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 5px 16px;
}
.InputComponents .container.active {
  outline: 2px solid var(--color-default);
  outline-offset: 1px;
}
.InputComponents .container .icon {
  font-size: 18px;
  color: var(--color-gray-bg);
  margin-right: 10px;
}
.InputComponents .container.active .icon {
  color: var(--color-default);
}
.InputComponents .container input {
  font-family: var(--font-family);
  width: 100%;
  outline: none;
  border: none;
  font-weight: 400;
  background: none;
}

.InputPhones .container {
  padding: 0;
}
.InputPhones .container input {
  padding: 5px 16px;
}
.InputPhones .nb-country {
  display: flex;
  background-color: #fafafb;
  color: #666;
  font-weight: 500;
  padding: 5px 10px;
  border-top-left-radius: var(--border-radius-little);
  border-bottom-left-radius: var(--border-radius-little);
  align-items: center;
}
