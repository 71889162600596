.Sidebar {
  display: flex;
  min-width: 260px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #f4f5f7;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.Sidebar .top .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  border-bottom: 1px solid #e4e8ee;
}
.Sidebar .top .account > div {
  display: flex;
  align-items: center;
}
.Sidebar .top .account .ctn-image {
  display: flex;
  align-items: center;
}
.Sidebar .top .account .ctn-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.Sidebar .top .account .ctn-image .ctn-status {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(28px, 14px);
  width: 12px;
  height: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}
.Sidebar .top .account .ctn-image .ctn-status .status {
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
}
.Sidebar .top .account p {
  font-weight: 500;
  margin-bottom: -5px;
}
.Sidebar .top .account span {
  font-size: 14px;
  color: var(--color-gray);
}
.Sidebar .top .account > div .icon {
  font-size: 20px;
  color: var(--color-gray);
}

.Sidebar .top .search {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin: 20px;
  border-radius: var(--br-default);
  background-color: white;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 5px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.Sidebar .top .search .icon {
  margin-right: 8px;
  color: #c8c8c8;
}

.Sidebar .top .menu {
  padding: 0 20px;
}
.Sidebar .top .menu .item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  text-decoration: none;
  outline: none;
  color: var(--color-gray);
  border-radius: var(--br-default);
  transition: var(--transition-default) color;
}
.Sidebar .top .menu .item:hover {
  color: var(--color-default-text);
}
.Sidebar .top .menu .item.active {
  background-color: #e4e8ee;
  color: black;
}
.Sidebar .top .menu .item .icon {
  margin-right: 10px;
}

.Sidebar .bottom {
  padding: 20px;
}
.Sidebar .bottom span {
  display: block;
  color: var(--color-gray);
  margin-bottom: -5px;
  font-size: 14px;
}
