.Home .wrapper {
  overflow-y: auto;
  height: calc(100vh - 94px);
  padding: 20px;
}
.Home .wrapper .list {
  display: flex;
  align-items: center;
  gap: 20px;
}
.Home .wrapper .list .item {
  width: 100%;
  padding: 20px;
  border-radius: var(--br-default);
  border: var(--border-default);
}
.Home .wrapper .top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.Home .wrapper .top .ctn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  font-size: 16px;
  color: var(--color-gray);
  background-color: var(--color-gray-bg);
  border-radius: 50%;
}
.Home .wrapper .list .item .flex {
  display: flex;
  align-items: center;
}
.Home .wrapper .list .item .flex span {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  color: white;
  padding: 3px 10px;
  border-radius: 30px;
}
.Home .wrapper .list .item .flex span.green {
  background-color: var(--color-green);
}
.Home .wrapper .list .item .flex span.red {
  background-color: var(--color-red);
}
.Home .wrapper .list .item .flex span .icon {
  font-size: 16px;
  margin-left: 5px;
}
.Home .wrapper .chart {
  overflow: hidden;
  height: 250px;
  padding: 20px;
  margin-top: 20px;
  border-radius: var(--br-default);
  border: var(--border-default);
}

.Home .wrapper > .project {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 20px;
  margin-top: 20px;
  padding-bottom: 50px;
}
.Home .project .flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Home .project .flex .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
  padding: 20px;
  border-radius: var(--br-default);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.Home .project .flex .container.business {
  background: linear-gradient(
    180deg,
    rgba(58, 181, 116, 1) 0%,
    rgba(55, 161, 105, 1) 100%
  );
}
.Home .project .flex .container.customer {
  background: linear-gradient(
    180deg,
    rgba(19, 134, 246, 1) 0%,
    rgba(19, 91, 246, 1) 100%
  );
}
.Home .project .flex .container.project {
  background: linear-gradient(
    180deg,
    rgba(251, 19, 81, 1) 0%,
    rgba(238, 12, 72, 1) 100%
  );
}
.Home .project .flex .container .icon {
  font-size: 20px;
}
.Home .project .chart {
  flex: 2;
  margin-top: 0px;
  height: 200px;
}
