.Search input {
  background: none;
  outline: none;
  border: none;
}
.Search input::placeholder,
.Search .icon {
  color: var(--color-placeholder);
}
.Search {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: var(--br-default);
  background-color: white;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 5px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.Search .icon {
  margin-right: 8px;
  color: #c8c8c8;
}
