.Pallet {
  display: inline-block;
  background-color: var(--color-gray-bg);
  padding: 2px 10px;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-gray);
}
.Pallet .flex {
  display: flex;
  align-items: center;
}
.Pallet svg {
  margin-right: 8px;
}
.Pallet span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: var(--color-green);
  margin-right: 8px;
}
