.Navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  height: 63px;
  border-bottom: 1px solid #e4e8ee;
}
.Navbar .flex {
  display: flex;
  align-items: center;
}
.Navbar .flex a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 5px;
  font-size: 25px;
  background-color: var(--color-gray-bg);
  margin-right: 15px;
  transition: var(--transition-default);
}
.Navbar .flex a:hover {
  background-color: var(--color-gray-bg-hover);
}
.Navbar span {
  display: block;
  margin-top: -3px;
  font-size: 14px;
  color: var(--color-gray);
}
