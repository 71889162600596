@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /* Font */
  --font-family: "Poppins", sans-serif;

  /* Color */
  --color-default: #136bf5;
  --color-default-hover: #1a4eea;
  --color-default-disabled: rgba(2, 99, 224, 0.9);
  --color-default-text: #171326;
  --color-default-bg: #ffffff;
  --color-placeholder: #333c49;

  --color-gray: #6b7280;
  --color-gray-hover: #575d6a;
  --color-gray-text: #a5a5a5;
  --color-grayblack-text: #45515b;
  --color-gray-bg: #f3f5f4;
  --color-gray-bg-hover: rgb(234, 234, 234);

  --color-default-border: #e4e8ee;
  --color-gray-border: #d1d5db;

  --color-red: #ee0b48;
  --color-red-hover: #c80923;
  --color-red-disabled: #c11f35;
  --color-red-bg: #feedee;
  --color-red-border: #ed8282;

  --color-green: #38a169;
  --color-green-border: rgb(35, 114, 72) 9;

  --color-yellow: #fee3ac;
  --color-yellow-border: rgb(238, 215, 169);

  --color-text-background: white;

  /* Bg */
  --bg-default: #ffffff;
  --bg-red: #fcefec;
  --bg-blue: #e8eefa;
  --bg-gray: #f9fafc;

  /* Padding */
  --padding-default: 20px;

  /* Border */
  --border-default: 1px solid #e4e8ee;
  --border-big: 2px solid #e4e8ee;
  --border-red: 1px solid #ecd1c9;
  --border-blue: 1px solid #b6cdec;
  --border-gray: 1px solid #d6d6d6;

  --br-default: 8px;
  --border-radius-default: 12px;
  --border-radius-little: 6px;

  /* Box-shadow */
  --box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  --box-shadow-ctn: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  /* transition */
  --transition-default: all 0.2s;
  --transition: all 0.2s;
}

*::before,
::after {
  font-family: var(--font-family);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--color-default-text);
}

/* G-Y8V1YX6YB8 */

body {
  font-family: var(--font-family);
  margin: 0;
  background-color: var(--color-default-bg);
  color: var(--color-default-text);
  overflow-y: hidden;
}

.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
  color: var(--color-gray-text);
  font-weight: 500;
}
.text-base {
  font-size: 1rem;
  color: var(--color-gray-text);
  font-weight: 500;
}
.text-lg {
  font-size: 1.125rem;
  font-weight: 500;
}
.text-xl {
  font-size: 1.25rem;
  font-weight: 500;
}
.text-2xl {
  font-size: 1.5rem;
  font-weight: 500;
}
.text-3xl {
  font-size: 1.875rem;
  font-weight: 700;
}
.text-4xl {
  font-size: 2.25rem;

  font-weight: 700;
}
.text-5xl {
  font-size: 3rem;

  font-weight: 700;
}
.text-6xl {
  font-size: 4rem;
  line-height: 4.2rem;
  font-weight: 600;
}

/* -------------------------------------------------------------- */
/* title */
h1 {
  font-size: 42px;
  font-weight: 600;
}
h2 {
  font-size: 36px;
  font-weight: 600;
  margin: 0;
}
h3 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}
h4 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
h5 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

/* -------------------------------------------------------------- */
/* ---------------------------- COLOR --------------------------- */

.green {
  color: var(--color-green);
}
.red {
  color: var(--color-red);
}

/* -------------------------------------------------------------- */
/* ---------------------------- CLASS --------------------------- */

.padding {
  padding: var(--padding-default);
}

.fw-500 {
  font-weight: 500;
}

/* -------------------------------------------------------------- */

/* Settings li | a */
li {
  list-style: none;
}

a {
  color: var(--color-default-text);
  font-weight: 500;
  outline: none;
}

/* other */
p {
  margin: 0;
}
input {
  font-family: var(--font-family);
  line-height: 0;
}
textarea {
  font-family: var(--font-family);
}
input[type="submit"] {
  outline: none;
  border: none;
}

input[type="radio"] {
  display: none;
}

.button--Under {
  color: var(--color_gray);
  padding: var(--padding);
  background: none;
  cursor: pointer;
}
.button-fill {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  background: none;
  color: var(--color-grayblack-text) !important;
  border-radius: var(--border-radius-little);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 5px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 8px 20px;
  transition: var(--transition);
}
.button-fill .icon-button {
  margin-right: 10px;
}
.button-fill:hover {
  background-color: var(--color-gray-bg);
}

.button--Option {
  color: var(--color_primary);
  padding: var(--padding);
  background: none;
  cursor: pointer;
  font-size: 18px;
}

.rows {
  display: flex;
  flex-wrap: wrap;
}
.none {
  outline: none;
  border: none;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.8 !important;
}

#test {
  display: block !important;
}

.errorLabelText {
  color: red !important;
  font-style: italic;
  font-weight: 600;
}

.errorData {
  border: 2px solid red !important;
}

/* new  */
.main {
  width: 100%;
}
