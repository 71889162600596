.AddCustomer .wrapper {
  padding: 20px 0;
  height: calc(100vh - 100px);
  overflow: auto;
}
.AddCustomer .wrapper .form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.AddCustomer .form .form-group {
  margin-bottom: 20px;
  padding: 0 20px 20px 20px;
  width: calc(100% - 40px);
  border-bottom: var(--border-default);
}
.AddCustomer .form .form-group .title {
  margin-bottom: 20px;
}
.AddCustomer .form .form-group h5 {
  font-size: 18px;
}
.AddCustomer .form .logo .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AddCustomer .form .logo .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AddCustomer .form .logo .flex .container {
  display: flex;
  align-items: center;
}
.AddCustomer .form .logo img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}
.AddCustomer .form .logo p {
  font-weight: 500;
  color: black;
}
.AddCustomer .form .logo span {
  font-weight: 500;
  color: #45515b;
}
.AddCustomer .form .form-group .InputComponents {
  min-width: 340px;
}
.AddCustomer .form .form-group.normal .inner > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}
.AddCustomer .form .form-group.add-input .inner .flex {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.AddCustomer .form .form-group.add-input .inner .flex > div {
  display: flex;
  flex-direction: column;
}
.AddCustomer .form .form-group.add-input .inner .flex .container div {
  margin-bottom: 20px;
}
.AddCustomer .form .form-group.add-input .inner .flex .container:last-child {
  margin-bottom: 0;
}
.AddCustomer .form .form-group.add-input .inner .flex .container div {
  width: calc(50% - 10px);
}
.AddCustomer .form .form-group.add-input .ctn-icon-form {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid var(--color-green);
  color: var(--color-green);
  font-size: 14px;
  margin-right: 10px;
}
.AddCustomer .form .form-group textarea {
  background-color: white;
  border-radius: var(--border-radius-little);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 5px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 15px;
  width: 670px;
  height: 100px;
  resize: none;
}
.AddCustomer .form .form-group textarea:focus {
  outline: 2px solid var(--color-default) !important;
  outline-offset: 1px !important;
}
.AddCustomer .form .ctn-button {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 40px);
  padding: 0 20px 30px 20px;
}

.AddCustomer .form .form-group .text-sm {
  font-weight: 500 !important;
  color: #45515b !important;
}
.AddCustomer .form .form-group .select {
  width: 340px;
}
.AddCustomer .form .form-group .css-b62m3t-container {
  width: 100%;
  border-radius: var(--border-radius-little);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 6px 5px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.AddCustomer .form .form-group .css-b62m3t-container div {
  border: none;
  border-radius: var(--br-default);
}
.AddCustomer .form .form-group .css-t3ipsp-control {
  box-shadow: none;
  outline: 2px solid var(--color-default) !important;
  outline-offset: 1px !important;
}
